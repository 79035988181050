<template>
  <v-main app>
    <v-row justify="center">
      <v-col md="6" sm="12">
          <v-card class="mt-16" flat outlined>
            <v-card-title
              class="
                mb-4
                text-xs-center
                justify-center
                primary
                title
                white--text
                font-weight-bold
              "
            >
              Login
            </v-card-title>
            <v-alert v-if="authenticationFailed" type="error">
              <p>Login failed. Please check email and password.</p>
            </v-alert>
            <v-card-text>
              <v-form class="mb-4" ref="loginForm" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="loginEmail"
                      :rules="loginEmailRules"
                      label="E-mail"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="loginPassword"
                      :append-icon="show1 ? 'eye' : 'eye-off'"
                      :rules="[rules.required, rules.min]"
                      :type="show1 ? 'text' : 'password'"
                      name="input-10-1"
                      label="Password"
                      hint="At least 8 characters"
                      counter
                      @click:append="show1 = !show1"
                    ></v-text-field>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col class="d-flex" cols="12" sm="3" xsm="12">
                    <v-btn
                      x-large
                      block
                      :disabled="!valid"
                      color="success"
                      @click="validate"
                    >
                      Login
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
              <p>Don't know your password?</p>
              <p>If you are an existing Doricmor customer or had previously ordered from our old website please click the 'reset link' button below to set a password.</p>
              <v-btn class="mb-4" to="/requestpasswordreset">
                Get reset link
              </v-btn>
              <p>If you are a new visitor, <router-link to="/register">apply for access here</router-link></p>
            </v-card-text>
          </v-card>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  metaInfo: {
    title: "Login to your account"
  },
  created () {
  },
  mounted () {
    this.showLogin = true
  },
  props: {
    value: Boolean
  },
  computed: {
  },
  methods: {
    validate () {
      if (this.$refs.loginForm.validate()) {
        const email = this.loginEmail
        const password = this.loginPassword
        this.$store
          .dispatch('login', { email, password })
          .then(() => {
            const route = this.$route.query?.returnurl || '/home'
            this.$emit('closeDialog')
            this.$router.push(route)
          })
          .catch(() => {
            console.log('failed')
            this.authenticationFailed = true
          })
      }
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    }
  },
  data: () => ({
    valid: true,
    isLoggedIn: false,
    authenticationFailed: false,
    loginPassword: '',
    loginEmail: '',
    loginEmailRules: [
      (v) => !!v || 'Required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
    ],
    show1: false,
    rules: {
      required: (value) => !!value || 'Required.',
      min: (v) => (v && v.length >= 8) || 'Min 8 characters'
    }
  })
}
</script>
<style scoped>
::v-deep .v-form .v-input {
  padding: 2px;
}
::v-deep input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-clip: text;
}
</style>
